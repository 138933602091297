<template>
  <div class="view">
    <b-card v-if="item">
      <b-row>
        <b-col>
          <b-list-group flush class="list-group-dividered">
            <b-list-group-item>
              <b>Статус: </b>
              <b-badge variant="light">Новая</b-badge>
            </b-list-group-item>
            <b-list-group-item style="display: flex"
              ><b>Ответственный: </b>
              <responsible v-if="item.responsible" :responsible="item.responsible"></responsible>
              <span v-else style="margin-left: 3px">нет</span>
            </b-list-group-item>
            <b-list-group-item>
              <b>Родительская: </b>
              <b-link v-if="item.parent" :to="'/tasks/list?filters[tasks][id]=' + item.parent.id">
                {{ item.parent.name }}
              </b-link>
              <template v-else>нет</template>
            </b-list-group-item>

            <b-list-group-item
              ><b>Проект: </b>
              <b-link v-if="item.project" :to="'/projects/list?filters[projects][id]=' + item.project.id">
                {{ item.project.name || 'Без названия' }}
              </b-link>
              <template v-else>-</template>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col>
          <b-list-group flush class="list-group-dividered">
            <b-list-group-item
              ><b>Задача в Asana: </b>
              <a
                v-if="item.extra.asana_task_id"
                :href="'https://app.asana.com/0/0/' + item.extra.asana_task_id + '/f'"
                target="_blank"
                >перейти</a
              >
              <template v-else>-</template>
            </b-list-group-item>
            <b-list-group-item><b>Asana HID: </b>{{ item.extra.asana_hook_id || '-' }}</b-list-group-item>
            <b-list-group-item
              ><b>TimeDoctor ID: </b>
              <a v-if="item.extra.td_id" :href="item.tdLink || '#'">{{ item.extra.td_id }}</a>
              <template v-else>-</template>
            </b-list-group-item>
            <b-list-group-item
              ><b>Создана: </b> {{ item.created_at | formatDate('DD.MM.YYYY HH:MM') }}
            </b-list-group-item>
            <b-list-group-item
              ><b>Закрыта: </b>
              <template v-if="item.closed_at">
                {{ item.closed_at | formatDate('DD.MM.YYYY HH:MM') }}
              </template>
              <template v-else>нет</template>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <b-tabs class="nav-tabs-line" content-class="pt-20" lazy>
        <b-tab title="Интервалы">
          <intervals entityName="tasks"></intervals>
        </b-tab>

        <b-tab title="Планирование"> </b-tab>
        <b-tab title="Деньги"> </b-tab>
      </b-tabs>
      <edit-task :source="item" :module="module" single></edit-task>
    </b-card>
  </div>
</template>

<script>
  import api from '@/assets/js/api'
  import queryHelper from '../../assets/js/query'

  import EditTask from './EditTask'
  import Intervals from '../../views/Reports/Intervals/Extension'
  import Responsible from '../../components/Responsible'

  import entityMixins from '../../mixins/entity'

  export default {
    name: 'SingleTask',
    components: {
      Responsible,
      EditTask,
      Intervals,
    },
    mixins: [entityMixins],
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$store.commit('currentView/setActions', [
          {
            label: 'Редактировать',
            icon: 'edit',
            onClick() {
              vm.$root.$emit('bv::show::modal', 'edit-tasks')
            },
          },
        ])
      })
    },
    watch: {
      item(item) {
        this.$store.commit('currentView/setMeta', {
          title: item.name,
        })
      },
    },
  }
</script>
